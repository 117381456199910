import React, {useEffect} from "react";
import {Button, Radio, Result} from 'antd'
import {LoadingOutlined} from '@ant-design/icons';
import logo from '../assets/animated_logo_160.svg'

const FallbackNotAuthenticatedUser = (props) => {

    useEffect(() => {
        //await Auth.federatedSignIn()
        //window.open("https://auth.quickhms.com/login?redirect_uri=" + props.redirectURI + "&response_type=code&client_id=uhj21ih0roblum2d844pq1eh8", "_top");
    })
    const handleConnectClicked = async () => {
        // await Auth.federatedSignIn()

        // give the user the option to switch accounts.
        //window.open("https://auth.quickhms.com/login?redirect_uri=" + props.redirectURI + "&response_type=code&client_id=uhj21ih0roblum2d844pq1eh8", "_top");
    }


    return (
        <Result
            icon={<img src={logo}/>}
            title=""
            // extra={<Button onClick={() => handleConnectClicked()} type="primary">Login</Button>}
            // extra={<Button onClick={() => handleConnectClicked()} type="primary">Login</Button>}
        />
    );
}
export default FallbackNotAuthenticatedUser;