import {
    Layout,
    Menu,
    Tag,
    Card,
    Row,
    Badge,
    Form,
    Spin,
    Tooltip,
    PageHeader, Button, Statistic, Descriptions,
    Col,
    Divider,
    DatePicker,
    Space,
    Select,
    Table,
    Typography,

    Dropdown,
    Modal,
    List,
    message,
    Input,
    Pagination,
    Skeleton,
    Breadcrumb
} from 'antd';
import {
    FilePptOutlined,
    LoadingOutlined,
    CloseOutlined,
    CodeSandboxOutlined,
    FileTextOutlined,
    MoreOutlined,
    LinkOutlined,
    DeleteOutlined,
    DownloadOutlined,
    SyncOutlined,
    ExclamationCircleOutlined
} from '@ant-design/icons';
import React, {useEffect, useRef, useState} from "react";
import {trackPromise, usePromiseTracker} from "react-promise-tracker";
import Auth from "@aws-amplify/auth";
import {API} from "@aws-amplify/api";
import {HashLoader} from "react-spinners";
import Moment from "react-moment";

const {Text, Link} = Typography;
const {confirm} = Modal;

const SurveyHistoryCard = (props) => {

    const {promiseInProgress} = usePromiseTracker({area: "exports"});

    const linkInputRef = useRef(null);
    const downloadLinkInputRef = useRef(null);

    const [readyExportsList, setReadyExportsList] = useState([]);

    const [socketConnected, setSocketConnected] = useState(false);


    const [downloadableLinkExportId, setDownloadableLinkExportId] = useState("");
    const [downloadableLinkModalOpen, setDownloadableLinkModalOpen] = useState(false);


    useEffect(() => {

        if (props.survey_id) {
            getSurveyHistory()
            //linkInputRef.current.select();
        }

    }, [props.survey_id])


    useEffect(() => {
        if (readyExportsList.length > 0) {
            let searchParams = window.location.search;
            const usp = new URLSearchParams(searchParams);


            for (var key of usp.keys()) {
                console.log(key)
            }
        }

    }, [readyExportsList])


    useEffect(() => {

        // if (props.survey_id) {
        //     getSurveyHistory()
        //     linkInputRef.current.select();
        // }

        setSocketConnected(props.socketConnectionStatusConnected)

    }, [props.socketConnectionStatusConnected])


    useEffect(() => {

        // when the main character passes the new status
        if (props.liveReceivedUpdatedStatus) {

            // is there an item with this key?
            const elementsIndex = readyExportsList.findIndex(exportItem => exportItem.s3_json_key === props.liveReceivedUpdatedStatus.s3_json_key)

            //console.log(elementsIndex)
            // no existing item, this is a new build, refresh the list
            if (elementsIndex === -1) {
                getSurveyHistory()
            } else {
                let tmpUpdatedArray = [...readyExportsList]

                tmpUpdatedArray[elementsIndex]["s3_presentation_key"] = props.liveReceivedUpdatedStatus.s3_presentation_key

                setReadyExportsList(tmpUpdatedArray)
            }
        }

    }, [props.liveReceivedUpdatedStatus])


    const downloadExportHandler = async (key, filetype) => {
        console.log("downloading " + key)

        try {
            const response = await trackPromise(downloadExportForKey(props.survey_id, key, filetype), "exports");
            window.open(response.data.downloadLink)

        } catch (error) {
            console.error(error);
            message.error(`Could not get export. Error: ${error.message}`, 10);
        }

    };


    const handleCloseModal = () => {
        setDownloadableLinkModalOpen(false)
    }

    const handleShowDownloadableLinkClick = (exportId) => {
        setDownloadableLinkExportId(exportId)
        setDownloadableLinkModalOpen(true)
    }
    const copyCodeToClipboard = (e) => {

        linkInputRef.current.select();
        document.execCommand('copy');
        // This is just personal preference.
        // I prefer to not show the the whole text area selected.
        //e.target.focus();

        message.success('Link copied to clipboard!');

    }

    async function getSurveyHistory() {
        try {
            const integrationsData = await trackPromise(loadSurveyExportsList(props.survey_id), "exports")

            setReadyExportsList(integrationsData.data.records)
        } catch (e) {
            message.error(`Could not get survey history. Error: ${e.message}`, 10);
        }
    }


    const handleExportDeletionClicked = (exportId, revisionDate) => {
        confirm({
            title: 'Permanently delete this version?',
            icon: <ExclamationCircleOutlined/>,
            okType: 'danger',
            style: {top: 20},
            okText: 'Delete',
            confirmLoading: true,
            width: 700,
            content:
                <p style={{marginTop: "2rem"}}>
                    <p>
                        <Text>Deleting the version of <Text strong><Moment
                            unix>{parseInt(revisionDate) / 1000}</Moment> </Text> will also result in:</Text>
                        <ul>
                            <li>This revision will no longer be editable.</li>
                            <li>Export files will permanently be deleted.</li>
                        </ul>
                    </p>
                    <p>
                        <Text type="danger">This action cannot be undone.</Text>
                    </p>
                </p>
            ,
            onOk() {
                return new Promise((resolve, reject) => {
                    (async () => {
                        await deleteExportVersion(exportId);
                        resolve()

                        getSurveyHistory()
                        message.success('Export version successfully deleted.');

                    })().catch(e => {
                        reject()
                    });
                }).catch(() => console.log('Oops errors!'));

            }
            ,
            onCancel() {
            }
            ,
        })
        ;
    }


    return (
        <Card className={"elevated"}
              title="Survey History"
              bordered={false}
              extra={<Space>
                  {props.socketConnectionError
                      ?
                      <Button type="link" size={"small"} onClick={props.onConnectionRetryClicked}>
                          Could not enable live updates, click to retry
                      </Button>
                      : <div>
                          {/*<Button type="default" className={"btn-spaced"}*/}
                          {/*                onClick={getSurveyHistory}*/}
                          {/*                icon={<ReloadOutlined/>}>refresh</Button>*/}
                          {socketConnected
                              ? <Badge status="processing" text="Auto update enabled"/>
                              : <Text><LoadingOutlined/> Trying to connect to live updates ..</Text>
                          }

                      </div>
                  }


              </Space>}
        >


            {/*<Paragraph copyable>{integrationData.survey_id ? `${process.env.REACT_APP_BASE_URL}/surveys/${integrationData.survey_id}` : "Loading.."}</Paragraph>*/}

            {/*<Divider orientation="center">History</Divider>*/}

            {promiseInProgress
                ? <Spin spinning={true} style={{width: "100%"}}
                        indicator={<LoadingOutlined className={"loading-spin"} style={{fontSize: "2rem"}} spin/>}/>
                :
                <List
                    className="demo-loadmore-list"
                    itemLayout="horizontal"
                    locale={{emptyText: "No survey submissions yet! Share the survey link with CP. Submission history will appear here!"}}
                    dataSource={readyExportsList}
                    renderItem={item => (
                        <List.Item
                            key={item.export_creation_date}
                            extra={<Space>


                                <Button className={"btn-special-2"} disabled={!item.s3_presentation_key}
                                        onClick={() => props.onVersionCompatibilityReportClicked(item)}
                                        icon={<CodeSandboxOutlined/>}>HMS Compatibility</Button>


                                <Dropdown overlay={<Menu>
                                    <Menu.Item icon={<FileTextOutlined/>} onClick={() => {
                                        downloadExportHandler(item.s3_json_key, "json")
                                    }} key="1">JSON</Menu.Item>
                                    {item.status && item.status === "status_export_powerpoint_failure"
                                        ? <Menu.Item key="2" disabled={true} icon={<CloseOutlined/>}>Not
                                            available</Menu.Item>

                                        : (!item.s3_presentation_key)
                                            ?
                                            <Menu.Item key="2" disabled={true} icon={<LoadingOutlined/>}>Powerpoint is
                                                Being
                                                generated ..</Menu.Item>
                                            : <Menu.Item icon={<FilePptOutlined/>} onClick={() => {
                                                downloadExportHandler(item.s3_presentation_key, "pptx")
                                            }} key="2">PowerPoint</Menu.Item>

                                    }

                                </Menu>}><Button>Download <DownloadOutlined /></Button></Dropdown>



                                <Button shape="circle" disabled={!item.s3_presentation_key}
                                        onClick={() => handleShowDownloadableLinkClick(item._id)}
                                        icon={<LinkOutlined/>}/>

                                <Dropdown overlay={<Menu>
                                    <Menu.Item icon={<DeleteOutlined/>} danger onClick={() => {
                                        handleExportDeletionClicked(item._id, item.export_creation_date)
                                    }} key="1">Delete Export</Menu.Item>


                                </Menu>} placement="bottomLeft">


                                    <Button type="secondary" shape="circle" icon={<MoreOutlined/>}/>
                                </Dropdown>

                            </Space>}
                        >
                            <Skeleton loading={promiseInProgress} active>
                                <List.Item.Meta
                                    title={<Row>
                                        <Col span={24}>
                                            <Text strong> <Moment format="DD-MMM-YY HH:mm"
                                                unix>{parseInt(item.export_creation_date) / 1000}</Moment>
                                            </Text> (<a key="list-loadmore-edit" onClick={(e) => {
                                            e.preventDefault()
                                            window.open(`${process.env.REACT_APP_BASE_URL}/surveys/${props.survey_id}?mode=edit&vHash=${item.s3_json_key}`)
                                        }}>Edit This Version</a>)
                                        </Col>
                                        {(item.status && item.status === "status_export_powerpoint_failure")
                                            ?
                                            <Col span={24}>
                                                <Text type="danger">Powerpoint Generation failed</Text>
                                            </Col>
                                            :
                                            (!item.s3_presentation_key)
                                            &&
                                            <Tag icon={<SyncOutlined spin/>} color="processing">
                                                processing
                                            </Tag>
                                        }
                                    </Row>}
                                />
                            </Skeleton>


                        </List.Item>
                    )}
                />
            }


            <Modal
                title="Download Link"
                width={700}
                visible={downloadableLinkModalOpen}
                onOk={handleCloseModal}
                onCancel={handleCloseModal}
            >
                <p>Anyone with this link will be able to download the presentation file of this export.</p>
                <Input
                    bordered={false}
                    ref={downloadLinkInputRef}
                    size="large"
                    className={"history-card-link-input"}
                    style={{color: "#40a9ff"}}
                    value={
                        `${process.env.REACT_APP_BASE_URL}/d/${downloadableLinkExportId}`
                    }
                    placeholder=""
                    suffix={
                        <Button icon={<LinkOutlined/>} onClick={() => {
                            downloadLinkInputRef.current.select();
                            document.execCommand('copy');
                            message.success('Link copied to clipboard!');
                        }
                        }>Copy</Button>
                    }/>
            </Modal>
        </Card>
    )

    /**
     * Get the list downloadable exports
     */
    async function loadSurveyExportsList(surveyId) {

        let apiName = 'quickHMS-api';
        let path = `/surveys/${surveyId}/exports`;
        let myInit = { // OPTIONAL
            headers: {
                Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
        }

        return await API.get(apiName, path, myInit)
    }


    async function downloadExportForKey(surveyId, fileKey, fileType) {

        let apiName = 'quickHMS-api';
        let path = `/surveys/${surveyId}/exports`;
        let myInit = { // OPTIONAL
            headers: {
                Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
            queryStringParameters: {
                exportKey: fileKey,
                exportType: fileType
            },
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
        }


        return await API.get(apiName, path, myInit)
    }


    async function deleteExportVersion(exportId) {

        let apiName = 'quickHMS-api';
        let path = `/exports/${exportId}`;
        let myInit = { // OPTIONAL
            headers: {
                Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
        }


        return await API.del(apiName, path, myInit)
    }
}

export default SurveyHistoryCard